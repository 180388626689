import { createContext, useContext } from 'react'

import type { Env } from '@/env.server'

type Context = {
  development: boolean
  env: Env
  userSignedIn: boolean
}

export const Current = createContext<Context>({
  development: true,
  env: {} as Env,
  userSignedIn: false
})

export const useCurrent = () => useContext(Current)
